import { useFormik } from "formik";
import React, {  useState } from "react";
import OrderSummary from "../orderSummary/OrderSummary";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useCreateOrderMutation } from "../../store/api/OrderSlice";
import toast from "react-hot-toast";
import useDebounce from "../../hooks/useDebounce";
import { useFetchShippingByCodeQuery } from "../../store/api/ShippingSlice";

function Checkout() {
  const [createOrder] = useCreateOrderMutation();
  const [PostalCodevalues, setPostalCodevalues] = useState(0);
  const [shippingCost, setShippingCost] = useState(0);
  const navigate = useNavigate();

  const { ...item } = useSelector((state) => state.cart);
  console.log("these are itmes in the cart", item?.cartItems);

  const vendor = item?.cartItems[0]?.author._id;

  console.log("This is vendor",vendor)

  if (!vendor) {
    navigate("/");
    toast.alert("This Product is out of Stock");
  }
  const initialValues = {
    name: "madhvi",
    email: "madhvirajput2129@gmail.com",
    phoneNumber: "1234567890",
    country: "USA",
    streetAddress: "jsbdjaksdba",
    city: "ajksdnjadk",
    state: "bjhbd",
    companyName: "hsdbjbd",
    zipCode: "110096",
    shippingOption: "standard",
    paymentMethod: "credit card",
    cardNumber: "123456789012",
    expirationDate: "12/23",
    securityCode: "123",
    // author: vendor,
    subTotal: item?.cartTotalAmount,
    shippingCost,
    shippingOption: "Credit Card",
    total: item?.cartTotalAmount + shippingCost,
    status: "Pending",
    cart:item
  };

  // Validation schema using Yup
  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    phoneNumber: Yup.string()
      .matches(/^\d{10}$/, "Phone number must be 10 digits")
      .required("Phone number is required"),
    country: Yup.string().required("Country is required"),
    streetAddress: Yup.string().required("Street address is required"),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    companyName: Yup.string().required("Company name is required"),
    zipCode: Yup.string()
      .matches(/^\d{6}$/, "ZIP/Postal code must be 6 digits")
      .required("ZIP/Postal code is required"),
    shippingOption: Yup.string().required("Shipping option is required"),
    paymentMethod: Yup.string().required("Payment method is required"),
    cardNumber: Yup.string()
      .matches(/^\d{12,19}$/, "Card number must be between 12-19 digits")
      .required("Card number is required"),
    expirationDate: Yup.string()
      .matches(
        /^(0[1-9]|1[0-2])\/\d{2}$/,
        "Invalid expiration date format (MM/YY)"
      )
      .required("Expiration date is required"),
    securityCode: Yup.string()
      .matches(/^\d{3}$/, "Security code must be 3 digits")
      .required("Security code is required"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      const total = values.subTotal + shippingCost;
      console.log("Final values with total:", { ...values, total });
  
      try {
       
        const response = await createOrder({
          ...values,
          total, // 
      }).unwrap();
        console.log("This is response", response);
        if (response?.success) {
          toast.success("Data submitted successfully!");
        } else {
          throw new Error("Submission failed");
        }
      } catch (error) {
        toast.error(`Error: ${error.message}`);
      }
    },
  });
  const PostalCode = useDebounce(PostalCodevalues, 400);

  const ShippingMethods = useFetchShippingByCodeQuery(PostalCode, {
    skip: !PostalCode, // Skip the query if there's no postal code
  });
  return (
    <div className="bg-gray-50">
      <div className="mx-auto max-w-screen-2xl px-3 sm:px-10">
        <div className="py-10 lg:py-12 px-0 2xl:max-w-screen-2xl w-full xl:max-w-screen-xl flex flex-col md:flex-row lg:flex-row">
          <div className="md:w-full lg:w-3/5 flex h-full flex-col order-2 sm:order-1 lg:order-1">
            <div className="mt-5 md:mt-0 md:col-span-2">
              <form onSubmit={formik.handleSubmit}>
                <div>
                  <div>
                    <h2 className="font-semibold text-base text-gray-700 pb-3">
                      Billing Details
                    </h2>
                    <div className="grid grid-cols-6 gap-6">
                      {/* Email Address */}
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="email"
                          className="block text-gray-500 font-medium text-sm leading-none mb-2"
                        >
                          Email Address*
                        </label>
                        <div className="relative">
                          <input
                            className="py-2 px-4 md:px-5 w-full appearance-none border text-sm opacity-75 text-input rounded-md placeholder-body min-h-12 transition duration-200 focus:ring-0 ease-in-out bg-white border-gray-200 focus:outline-none focus:border-emerald-500 h-11 md:h-12"
                            id="email"
                            name="email"
                            type="email"
                            placeholder="Enter your email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </div>
                        {formik.errors.email && formik.touched.email && (
                          <span className="text-red-400 text-sm mt-2">
                            {formik.errors.email}
                          </span>
                        )}
                      </div>

                      {/* First Name */}
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="email"
                          className="block text-gray-500 font-medium text-sm leading-none mb-2"
                        >
                          Name*
                        </label>
                        <input
                          className="py-2 px-4 md:px-5 w-full appearance-none border text-sm opacity-75 text-input rounded-md placeholder-body min-h-12 transition duration-200 focus:ring-0 ease-in-out bg-white border-gray-200 focus:outline-none focus:border-emerald-500 h-11 md:h-12"
                          type="text"
                          name="name"
                          value={formik.values.name}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.name && formik.errors.name && (
                          <div className="error">{formik.errors.name}</div>
                        )}
                      </div>

                      {/* Company Name */}
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="companyName"
                          className="block text-gray-500 font-medium text-sm leading-none mb-2"
                        >
                          Company Name (optional)
                        </label>
                        <div className="relative">
                          <input
                            className="py-2 px-4 md:px-5 w-full appearance-none border text-sm opacity-75 text-input rounded-md placeholder-body min-h-12 transition duration-200 focus:ring-0 ease-in-out bg-white border-gray-200 focus:outline-none focus:border-emerald-500 h-11 md:h-12"
                            id="companyName"
                            name="companyName"
                            placeholder=""
                            value={formik.values.companyName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </div>
                      </div>

                      {/* Country / Region */}
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="country"
                          className="block text-gray-500 font-medium text-sm leading-none mb-2"
                        >
                          Country
                        </label>
                        <div className="relative">
                          <select
                            as="select"
                            className="py-2 px-4 md:px-5 w-full appearance-none border text-sm opacity-75 text-input rounded-md min-h-12 transition duration-200 focus:ring-0 ease-in-out bg:white border-gray-200 focus:outline-none focus:border-emerald-500 h-11 md:h-12"
                            id="country"
                            name="country"
                            value={formik.values.country}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          >
                            <option values="USA">United States</option>
                          </select>
                        </div>

                        {formik.errors.country && formik.touched.country && (
                          <span className="text-red-400 text-sm mt-2">
                            {formik.errors.country}
                          </span>
                        )}
                      </div>
                      {/* Street Address */}
                      <div className="col-span-6">
                        <label
                          htmlFor="streetAddress"
                          className="block text-gray-500 font-medium text-sm leading-none mb-2"
                        >
                          Street Address*
                        </label>
                        <div className="relative">
                          <input
                            type="text"
                            className="py-2 px-4 md:px-5 w-full appearance-none border text-sm opacity-75 text-input rounded-md placeholder-body min-h-12 transition duration-200 focus:ring-0 ease-in-out bg:white border-gray-200 focus:outline-none focus:border-emerald-500 h-11 md:h-12"
                            id="streetAddress"
                            name="streetAddress"
                            placeholder="House number and street name"
                            value={formik.values.streetAddress}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </div>
                        {formik.errors.streetAddress &&
                          formik.touched.streetAddress && (
                            <span className="text-red-400 text-sm mt-2">
                              {formik.errors.streetAddress}
                            </span>
                          )}
                      </div>
                      {/* City -- Town */}
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="city"
                          className="block text-gray-500 font-medium text-sm leading-none mb-2"
                        >
                          Town / City*
                        </label>
                        <div className="relative">
                          <input
                            type="text"
                            className="py-2 px-4 md:px-5 w-full appearance-none border text-sm opacity-75 text-input rounded-md placeholder-body min-h-12 transition duration-200 focus:ring-0 ease-in-out bg:white border-gray-200 focus:outline-none focus:border-emerald-500 h-11 md:h-12"
                            id="city"
                            name="city"
                            placeholder="Enter city"
                            value={formik.values.city}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </div>
                        {formik.errors.city && formik.touched.city && (
                          <span className="text-red-400 text-sm mt-2">
                            {formik.errors.city}
                          </span>
                        )}
                      </div>

                      {/* State */}
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="state"
                          className="block text-gray-500 font-medium text-sm leading-none mb-2"
                        >
                          State*
                        </label>
                        <div className="relative">
                          <input
                            type="text"
                            className="py-2 px-4 md:px-5 w-full appearance-none border text-sm opacity-75 text-input rounded-md placeholder-body min-h-12 transition duration-200 focus:ring-0 ease-in-out bg:white border-gray-200 focus:outline-none focus:border-emerald-500 h-11 md:h-12"
                            id="state"
                            name="state"
                            placeholder="Enter state"
                            value={formik.values.state}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </div>
                        {formik.errors.state && formik.touched.state && (
                          <span className="text-red-400 text-sm mt-2">
                            {formik.errors.state}
                          </span>
                        )}
                      </div>
                      {/* ZIP Code */}
                      <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                        <label
                          htmlFor="zipCode"
                          className="block text-gray-500 font-medium text-sm leading-none mb-2"
                        >
                          ZIP / Postal*
                        </label>
                        <div className="relative">
                          <input
                            type="number"
                            className="py-2 px-4 md:px-5 w-full appearance-none border text-sm opacity-75 text-input rounded-md placeholder-body min-h-12 transition duration-200 focus:ring-0 ease-in-out bg:white border-gray-200 focus:outline-none focus:border-emerald-500 h-11 md:h-12"
                            id="zipCode"
                            name="zipCode"
                            placeholder="Enter ZIP/Postal code"
                            value={formik.values.zipCode}
                            onChange={(e) => {
                              formik.handleChange(e); // Formik's handleChange
                              setPostalCodevalues(e.target.value); 
                            }}
                            onBlur={formik.handleBlur} // Handle blur event
                          />
                        </div>
                        {formik.errors.zipCode &&
                          formik.touched.zipCode && (
                            <span className="text-red-400 text-sm mt-2">
                              {formik.errors.zipCode}
                            </span>
                          )}
                      </div>

                      {/* Phone Number */}
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="phoneNumber"
                          className="block text-gray-500 font-medium text-sm leading-none mb-2"
                        >
                          Phone Number*
                        </label>
                        <div className="relative">
                          <input
                            type="number"
                            className="py-2 px-4 md:px-5 w-full appearance-none border text-sm opacity-75 text-input rounded-md placeholder-body min-h-12 transition duration-200 focus:ring-0 ease-in-out bg:white border-gray-200 focus:outline-none focus:border-emerald-500 h-11 md:h-12"
                            id="phoneNumber"
                            name="phoneNumber"
                            placeholder="Enter your phone number"
                            value={formik.values.phoneNumber}
                            onChange={formik.handleChange} // Formik's handleChange
                            onBlur={formik.handleBlur} // Handle blur event
                          />
                        </div>
                        {formik.errors.phoneNumber &&
                          formik.touched.phoneNumber && (
                            <span className="text-red-400 text-sm mt-2">
                              {formik.errors.phoneNumber}
                              {console.log(formik.errors.phoneNumber)}{" "}
                              {/* Debugging error */}
                            </span>
                          )}
                      </div>
                    </div>
                    {/* Shipping Cost---- */}
                    <div className="mt-2 mb-2">
                      <label className="block text-gray-500 font-medium text-sm leading-none mb-2">
                        Shipping Cost
                      </label>
                      {(!ShippingMethods?.data ||
                        ShippingMethods?.data?.length < 1) && (
                        <div className="text-center text-black text-sm bg-white rounded shadow p-4">
                          <p>
                            {ShippingMethods?.data?.length < 1
                              ? "Change"
                              : "Enter"}{" "}
                            Your Postal Code to View your Shipping Methods
                          </p>
                        </div>
                      )}
                      <div className="grid grid-cols-6 gap-6">
                        {ShippingMethods?.data?.length > 0 &&
                          ShippingMethods?.data?.map((item) => (
                            <div
                              className="col-span-6 sm:col-span-3"
                              key={item?._id}
                            >
                              <div>
                                <div className="p-3 border border-gray-200 bg-white rounded-md ">
                                  <label className="cursor-pointer label">
                                    <div className="flex items-center justify-between">
                                      <div className="flex items-center">
                                        <span className="text-2xl mr-3 text-gray-400">
                                          <svg
                                            stroke="currentColor"
                                            fill="none"
                                            strokeWidth="2"
                                            viewBox="0 0 24 24"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            height="1em"
                                            width="1em"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <rect
                                              x="1"
                                              y="3"
                                              width="15"
                                              height="13"
                                            ></rect>
                                            <polygon points="16 8 20 8 23 11 23 16 16 16 16 8"></polygon>
                                            <circle
                                              cx="5.5"
                                              cy="18.5"
                                              r="2.5"
                                            ></circle>
                                            <circle
                                              cx="18.5"
                                              cy="18.5"
                                              r="2.5"
                                            ></circle>
                                          </svg>
                                        </span>
                                        <div>
                                          <h6 className=" font-medium text-sm text-gray-600">
                                            {item?.name}
                                          </h6>
                                          <p className="text-xs text-gray-500 font-medium">
                                            {/* Delivery: 7 Days{" "} */}
                                            <span className="font-medium text-gray-600">
                                              Cost : ${item?.cost}
                                            </span>
                                          </p>
                                        </div>
                                      </div>
                                      <input
                                        type="radio"
                                        name="shippingOption"
                                        values={item?._id}
                                        className="form-radio outline-none focus:ring-0 text-emerald-500"
                                        onChange={(e) => {
                                          // handleChange(e);
                                          setShippingCost(item?.cost);
                                        }}
                                      />
                                    </div>
                                  </label>
                                </div>
                              </div>
                              {formik.errors.shippingOption &&
                                formik.touched.shippingOption && (
                                  <span className="text-red-400 text-sm mt-2">
                                    {formik.errors.shippingOption}
                                  </span>
                                )}
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
                {/* ----card Number--- */}
                <div className="grid grid-cols-3 gap-4 lg:gap-6">
                  <div>
                    <label
                      htmlFor="cardNumber"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Card Number*
                    </label>
                    <input
                      type="text"
                      id="cardNumber"
                      name="cardNumber"
                      placeholder="1234 5678 9012 3456"
                      maxLength="19"
                      className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      value={formik.values.cardNumber}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.errors.cardNumber && formik.touched.cardNumber && (
                      <span className="text-red-400 text-sm mt-2">
                        {formik.errors.cardNumber}
                      </span>
                    )}
                  </div>

                  <div>
                    <label
                      htmlFor="expirationDate"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Expiration Date*
                    </label>
                    <input
                      type="text"
                      id="expirationDate"
                      name="expirationDate"
                      placeholder="MM/YY"
                      maxLength="5"
                      className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      value={formik.values.expirationDate}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.errors.expirationDate &&
                      formik.touched.expirationDate && (
                        <span className="text-red-400 text-sm mt-2">
                          {formik.errors.expirationDate}
                        </span>
                      )}
                  </div>

                  <div>
                    <label
                      htmlFor="securityCode"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Security Code (CVV)*
                    </label>
                    <input
                      type="password"
                      id="securityCode"
                      name="securityCode"
                      placeholder="123"
                      maxLength="4"
                      className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      value={formik.values.securityCode}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.errors.securityCode &&
                      formik.touched.securityCode && (
                        <span className="text-red-400 text-sm mt-2">
                          {formik.errors.securityCode}
                        </span>
                      )}
                  </div>
                </div>
                <div className="grid grid-cols-6 gap-4 lg:gap-6 mt-10">
                  <div className="col-span-6 sm:col-span-3">
                    <Link
                      className="bg-indigo-50 border !no-underline border-indigo-100 rounded py-3 text-center text-sm font-medium !text-gray-700  hover:!text-gray-800 hover:border-gray-300 transition-all flex justify-center w-full"
                      to="/"
                    >
                      <span className="text-xl mr-2">
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth="0"
                          viewBox="0 0 512 512"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="32"
                            d="M112 160l-64 64 64 64"
                          ></path>
                          <path
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="32"
                            d="M64 224h294c58.76 0 106 49.33 106 108v20"
                          ></path>
                        </svg>
                      </span>
                      Continue Shopping
                    </Link>
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <button
                      type="submit"
                      className="bg-emerald-500 hover:bg-emerald-600 border border-emerald-500 transition-all rounded py-3 text-center text-sm  font-medium text-white flex justify-center w-full"
                    >
                      Confirm Order
                      <span className="text-xl ml-2">
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth="0"
                          viewBox="0 0 512 512"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="48"
                            d="M268 112l144 144-144 144m124-144H100"
                          ></path>
                        </svg>
                      </span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <OrderSummary shippingCost={shippingCost} />
        </div>
      </div>
    </div>
  );
}

export default Checkout;
