import React from 'react'

const ShopByCategory = () => {
  return (
    <div>
        <section class="py-16 px-4 bg-gray-50">
    <h2 class="text-3xl font-semibold text-center mb-8">Shop by Category</h2>
    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
        {/* <!-- Category 1 --> */}
        <div class="flex flex-col items-center bg-white p-6 rounded-lg shadow-lg hover:shadow-2xl transition-all duration-300">
            <img src="https://via.placeholder.com/150" alt="Category 1" class="w-32 h-32 object-cover mb-4 rounded-full"/>
            <div class="text-center">
                <h3 class="text-lg font-semibold text-gray-800">Category 1</h3>
                <p class="text-sm text-gray-600">Explore the latest products in this category.</p>
            </div>
        </div>
        {/* <!-- Category 2 --> */}
        <div class="flex flex-col items-center bg-white p-6 rounded-lg shadow-lg hover:shadow-2xl transition-all duration-300">
            <img src="https://via.placeholder.com/150" alt="Category 2" class="w-32 h-32 object-cover mb-4 rounded-full"/>
            <div class="text-center">
                <h3 class="text-lg font-semibold text-gray-800">Category 2</h3>
                <p class="text-sm text-gray-600">Explore the latest products in this category.</p>
            </div>
        </div>
        {/* <!-- Category 3 --> */}
        <div class="flex flex-col items-center bg-white p-6 rounded-lg shadow-lg hover:shadow-2xl transition-all duration-300">
            <img src="https://via.placeholder.com/150" alt="Category 3" class="w-32 h-32 object-cover mb-4 rounded-full"/>
            <div class="text-center">
                <h3 class="text-lg font-semibold text-gray-800">Category 3</h3>
                <p class="text-sm text-gray-600">Explore the latest products in this category.</p>
            </div>
        </div>
        {/* <!-- Category 4 --> */}
        <div class="flex flex-col items-center bg-white p-6 rounded-lg shadow-lg hover:shadow-2xl transition-all duration-300">
            <img src="https://via.placeholder.com/150" alt="Category 4" class="w-32 h-32 object-cover mb-4 rounded-full"/>
            <div class="text-center">
                <h3 class="text-lg font-semibold text-gray-800">Category 4</h3>
                <p class="text-sm text-gray-600">Explore the latest products in this category.</p>
            </div>
        </div>
    </div>
</section>

    </div>
  )
}

export default ShopByCategory