import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const apiUrl = process.env.REACT_APP_API_BASE_URL;

export const authApiSlice = createApi({
    reducerPath: 'authApi',
    baseQuery: fetchBaseQuery({ baseUrl: apiUrl }),
    endpoints: (builder) => ({
        loginUser: builder.mutation({
            query: (data) => ({
                url: '/user/login',
                method: 'POST',
                body: data,
            }),
        }),
        verifyEmail: builder.mutation({
            query: (data) => ({
                url: '/user/verify-email',
                method: 'POST',
                body: data,
            }),
        }),
        registerUser: builder.mutation({
            query: (data) => ({
                url: `/user/register/${data}`,
                method: 'POST',
            }),
        }),
        forgotPwd: builder.mutation({
            query: (data) => ({
                url: `/user/forget-password`,
                method: 'PUT',
                body: data
            }),
        }),
        resetPwd: builder.mutation({
            query: (data) => ({
                url: `/user/reset-password`,
                method: 'PUT',
                body: data
            }),
        }),
        // ------For Vendor registration this route is working-----------------
        vendorRegister: builder.mutation({
            query: (data) => {
                const formData = new FormData();
                for (const key in data) {
                    formData.append(key, data[key]);
                }
        
                return {
                    url: '/admin/register',
                    method: 'POST',
                    body: formData,
                };
            },
        }),
        
    }),
});

export const {
    useLoginUserMutation,
    useVerifyEmailMutation,
    useRegisterUserMutation,
    useForgotPwdMutation,
    useResetPwdMutation,
    useVendorRegisterMutation
} = authApiSlice;

export const authApiReducer = authApiSlice.reducer;

