import React from 'react'
import { Link } from 'react-router-dom'

function OrganicProductCard() {
  return (
    <div className="bg-orange-100 px-10 py-6 rounded-lg mt-6 hidden lg:block">
        <div className="flex items-center justify-between">
            <div>
                <h1 className="text-xl">
                    <span className="text-emerald-600 font-bold">Explore Our Top-Tier </span>
                    Nicotine Selection.
                </h1>
                <p className="text-gray-500">
                Don't miss out on exclusive discounts for your favorite nicotine items. <Link className="ml-1 !no-underline !text-emerald-600" to="/#discount"> Claim yours today!</Link> 
                </p>
            </div>
            <Link className="flex justify-center items-center text-sm font-serif font-medium px-6 h-9  !bg-emerald-500 text-center rounded-full !text-white !no-underline hover:!bg-emerald-700" to="/allProducts">Shop Now</Link>
        </div>
    </div>
  )
}

export default OrganicProductCard