

const statesAndCities = [
    {
      state: "Alabama",
      cities: ["Birmingham", "Montgomery", "Huntsville", "Mobile", "Tuscaloosa"]
    },
    {
      state: "Alaska",
      cities: ["Anchorage", "Fairbanks", "Juneau", "Sitka", "Ketchikan"]
    },
    {
      state: "Arizona",
      cities: ["Phoenix", "Tucson", "Mesa", "Chandler", "Glendale"]
    },
    {
      state: "Arkansas",
      cities: ["Little Rock", "Fort Smith", "Fayetteville", "Springdale", "Jonesboro"]
    },
    {
      state: "California",
      cities: ["Los Angeles", "San Francisco", "San Diego", "Sacramento", "Fresno"]
    },
    {
      state: "Colorado",
      cities: ["Denver", "Colorado Springs", "Aurora", "Fort Collins", "Boulder"]
    },
    {
      state: "Connecticut",
      cities: ["Hartford", "New Haven", "Stamford", "Bridgeport", "Waterbury"]
    },
    {
      state: "Delaware",
      cities: ["Wilmington", "Dover", "Newark", "Middletown", "Rehoboth Beach"]
    },
    {
      state: "Florida",
      cities: ["Miami", "Orlando", "Tampa", "Jacksonville", "St. Petersburg"]
    },
    {
      state: "Georgia",
      cities: ["Atlanta", "Augusta", "Columbus", "Macon", "Savannah"]
    },
    {
      state: "Hawaii",
      cities: ["Honolulu", "Hilo", "Kailua", "Kapolei", "Pearl City"]
    },
    {
      state: "Idaho",
      cities: ["Boise", "Nampa", "Meridian", "Idaho Falls", "Pocatello"]
    },
    {
      state: "Illinois",
      cities: ["Chicago", "Aurora", "Naperville", "Joliet", "Rockford"]
    },
    {
      state: "Indiana",
      cities: ["Indianapolis", "Fort Wayne", "Evansville", "South Bend", "Carmel"]
    },
    {
      state: "Iowa",
      cities: ["Des Moines", "Cedar Rapids", "Davenport", "Sioux City", "Iowa City"]
    },
    {
      state: "Kansas",
      cities: ["Wichita", "Overland Park", "Kansas City", "Topeka", "Olathe"]
    },
    {
      state: "Kentucky",
      cities: ["Louisville", "Lexington", "Bowling Green", "Owensboro", "Covington"]
    },
    {
      state: "Louisiana",
      cities: ["New Orleans", "Baton Rouge", "Shreveport", "Lafayette", "Metairie"]
    },
    {
      state: "Maine",
      cities: ["Portland", "Lewiston", "Bangor", "Auburn", "South Portland"]
    },
    {
      state: "Maryland",
      cities: ["Baltimore", "Silver Spring", "Frederick", "Rockville", "Gaithersburg"]
    },
    {
      state: "Massachusetts",
      cities: ["Boston", "Worcester", "Springfield", "Cambridge", "Lowell"]
    },
    {
      state: "Michigan",
      cities: ["Detroit", "Grand Rapids", "Ann Arbor", "Lansing", "Flint"]
    },
    {
      state: "Minnesota",
      cities: ["Minneapolis", "Saint Paul", "Rochester", "Duluth", "Bloomington"]
    },
    {
      state: "Mississippi",
      cities: ["Jackson", "Gulfport", "Southaven", "Hattiesburg", "Biloxi"]
    },
    {
      state: "Missouri",
      cities: ["Kansas City", "St. Louis", "Springfield", "Columbia", "Independence"]
    },
    {
      state: "Montana",
      cities: ["Billings", "Missoula", "Great Falls", "Bozeman", "Butte"]
    },
    {
      state: "Nebraska",
      cities: ["Omaha", "Lincoln", "Bellevue", "Grand Island", "Kearney"]
    },
    {
      state: "Nevada",
      cities: ["Las Vegas", "Reno", "Henderson", "North Las Vegas", "Sparks"]
    },
    {
      state: "New Hampshire",
      cities: ["Manchester", "Nashua", "Concord", "Derry", "Keene"]
    },
    {
      state: "New Jersey",
      cities: ["Newark", "Jersey City", "Paterson", "Elizabeth", "Edison"]
    },
    {
      state: "New Mexico",
      cities: ["Albuquerque", "Santa Fe", "Las Cruces", "Rio Rancho", "Roswell"]
    },
    {
      state: "New York",
      cities: ["New York City", "Buffalo", "Rochester", "Syracuse", "Albany"]
    },
    {
      state: "North Carolina",
      cities: ["Charlotte", "Raleigh", "Greensboro", "Durham", "Winston-Salem"]
    },
    {
      state: "North Dakota",
      cities: ["Fargo", "Bismarck", "Grand Forks", "Minot", "West Fargo"]
    },
    {
      state: "Ohio",
      cities: ["Columbus", "Cleveland", "Cincinnati", "Toledo", "Akron"]
    },
    {
      state: "Oklahoma",
      cities: ["Oklahoma City", "Tulsa", "Norman", "Broken Arrow", "Edmond"]
    },
    {
      state: "Oregon",
      cities: ["Portland", "Salem", "Eugene", "Gresham", "Beaverton"]
    },
    {
      state: "Pennsylvania",
      cities: ["Philadelphia", "Pittsburgh", "Allentown", "Erie", "Reading"]
    },
    {
      state: "Rhode Island",
      cities: ["Providence", "Warwick", "Cranston", "Pawtucket", "East Providence"]
    },
    {
      state: "South Carolina",
      cities: ["Columbia", "Charleston", "Greenville", "Mount Pleasant", "Rock Hill"]
    },
    {
      state: "South Dakota",
      cities: ["Sioux Falls", "Rapid City", "Aberdeen", "Brookings", "Pierre"]
    },
    {
      state: "Tennessee",
      cities: ["Nashville", "Memphis", "Knoxville", "Chattanooga", "Murfreesboro"]
    },
    {
      state: "Texas",
      cities: ["Houston", "Dallas", "Austin", "San Antonio", "Fort Worth"]
    },
    {
      state: "Utah",
      cities: ["Salt Lake City", "Provo", "West Valley City", "Orem", "Sandy"]
    },
    {
      state: "Vermont",
      cities: ["Burlington", "Montpelier", "Rutland", "Barre", "Stowe"]
    },
    {
      state: "Virginia",
      cities: ["Virginia Beach", "Norfolk", "Chesapeake", "Richmond", "Newport News"]
    },
    {
      state: "Washington",
      cities: ["Seattle", "Spokane", "Tacoma", "Vancouver", "Bellevue"]
    },
    {
      state: "West Virginia",
      cities: ["Charleston", "Huntington", "Morgantown", "Parkersburg", "Wheeling"]
    },
    {
      state: "Wisconsin",
      cities: ["Milwaukee", "Madison", "Green Bay", "Kenosha", "Racine"]
    },
    {
      state: "Wyoming",
      cities: ["Cheyenne", "Casper", "Laramie", "Gillette", "Rock Springs"]
    }
  ];
  

  export default statesAndCities;