import { useEffect } from "react";

const ThankYouForRegistering = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="bg-gray-50 h-[50vh] flex items-center justify-center">
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full text-center">
        <h1 className="text-4xl font-bold text-green-600 mb-4">
          Thank You for Registering!
        </h1>
        <p className="text-lg text-gray-700 mb-6">
      We will reach out to you once the verification process is complete.
        </p>
      
      </div>
    </div>
  );
};

export default ThankYouForRegistering;
