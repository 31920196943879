import React, { useEffect, useState } from "react";
import { useVendorRegisterMutation } from "../../store/api/AuthSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import statesAndCities from "../../json/CityAndState";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
const VendorRegister = () => {
  const navigate = useNavigate();
  const [vendorRegister] = useVendorRegisterMutation();

  // Initial form values
  const initialValues = {
    role: "Vendor",
    username: "",
    password: "",
    firstName: "",
    lastName: "",
    contactPersonTitle: "",
    email: "",
    phone: "",
    city: "",
    state: "",
    zipcode: "",
    businessName: "",
    numberOfLocations: "",
    primaryBusinessFocus: "",
    businessAddress: "",
    businessWebsiteAddress: "",
    address2: "",
    businessDescription: "",
    federalBusinessId: "",
    taxId: "",
    headquartersAddress: "",
    businessVerificationDocument: null,
    heardAboutUs: "Google search",
    permission:'false'
  };

  // Validation schema using Yup
  const validationSchema = Yup.object({
    role: Yup.string().default("Vendor"),
    username: Yup.string()
      .required("Username is required")
      .matches(/^[A-Za-z0-9]+$/, "No spaces allowed in username"),
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters"),
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    contactPersonTitle: Yup.string().required(
      "Contact Person Title is required"
    ),
    email: Yup.string()
      .email("Invalid email address")
      .required("Contact Person Email is required"),
    phone: Yup.string()
      .required("Mobile Number is required")
      .matches(/^\d{10}$/, "Mobile Number must be exactly 10 digits"),
    city: Yup.string()
      .matches(/^[a-zA-Z\s]+$/, "City name must only contain letters")
      .required("City is required"),
    state: Yup.string().required("State is required"),
    zipcode: Yup.string()
      .matches(
        /^\d{5}(-\d{4})?$/,
        "Zipcode must be a valid U.S. zip code (e.g., 12345 or 12345-6789)"
      )
      .required("Zipcode is required"),
    businessName: Yup.string().required("Business Name is required"),
    numberOfLocations: Yup.number()
      .typeError("Number of Locations must be a valid number")
      .required("Number of Locations is required")
      .positive("Number of Locations must be a positive number")
      .integer("Number of Locations must be an integer"),
    primaryBusinessFocus: Yup.string().required(
      "Primary Business Focus is required"
    ),
    businessAddress: Yup.string().required("Business Address is required"),
    businessWebsiteAddress: Yup.string()
      .url("Invalid URL")
      .required("Business Website is required"),
    businessDescription: Yup.string().required(
      "Business Description is required"
    ),

    federalBusinessId:Yup.string()
    .matches(/^\d{9}$/, "Federal Id must be exactly 9 digits")
    .required("Federal ID is required"),
    taxId: Yup.string()
      .matches(/^\d{9}$/, "Tax ID must be exactly 9 digits")
      .required("Tax ID is required"),
    headquartersAddress: Yup.string().required(
      "Headquarters Address is required"
    ),
    businessVerificationDocument: Yup.mixed().required(
      "Business Verification Document is required"
    ),
    heardAboutUs: Yup.string().required("Source is required"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      window.scrollTo({ top: 0, behavior: "smooth" });
      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        formData.append(key, values[key]);
      });
      if (values.businessVerificationDocument) {
        formData.append(
          "businessVerificationDocument",
          values.businessVerificationDocument
        );
      }
      try {
        const response = await vendorRegister(values).unwrap();
        if (response?.success) {
          toast.success("Vendor registered successfully!");
          setTimeout(() => {
            navigate("/registration-complete");
          }, 2000);
        } else {
          await toast.success("Vendor registered successfully.", {
            autoClose: 5000,
            position: "top-right",
          });
          setTimeout(() => {
            navigate("/registration-complete");
          }, 2000);
        }
      } catch (err) {
        toast.error("Email or Phone No. already Exists");
        console.log("Error in submission", err);
      }
    },
  });

  // Handle file change
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    formik.setFieldValue("businessVerificationDocument", file);
  };



  return (
    <div className="max-w-4xl mx-auto py-8">
      <ToastContainer />
      <h1 className="text-3xl font-semibold text-center mb-6 text-gray-700 ">
        Business Verification Form
      </h1>

      <form
        className="bg-white p-6 rounded-lg shadow-lg space-y-6 border-2 border-green-300"
        onSubmit={formik.handleSubmit}
      >
        <div className="hidden">
          <label className="block text-sm font-medium text-gray-700">
            Role
          </label>
          <input
            type="text"
            name="role"
            value="Vendor"
            readOnly
            className="mt-1 w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
          />
          {formik.touched.role && formik.errors.role && (
            <div className="text-red-500 text-sm font-bold">
              {formik.errors.role}
            </div>
          )}
        </div>
        <div className="hidden">
          <label className="block text-sm font-medium text-gray-700">
            Permission
          </label>
          <input
            type="text"
            name="permission"
            value="permission"
            readOnly
            className="mt-1 w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
          />
          {formik.touched.permission && formik.errors.permission && (
            <div className="text-red-500 text-sm font-bold">
              {formik.errors.permission}
            </div>
          )}
        </div>
        {/* Username */}
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Username*
            </label>
            <input
              type="text"
              name="username"
              placeholder="Username - space not allowed"
              className="mt-1 w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.username}
            />
            {formik.touched.username && formik.errors.username ? (
              <div className="text-red-500 text-sm font-bold">
                {formik.errors.username}
              </div>
            ) : null}
          </div>

          {/* Password */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Password*
            </label>
            <input
              id="password"
              type="password"
              name="password"
              placeholder="Desired password"
              className="mt-1 w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
            />
            {formik.touched.password && formik.errors.password ? (
              <div className="text-red-500 text-sm font-bold">
                {formik.errors.password}
              </div>
            ) : null}
          </div>
        </div>

        {/* First Name & Last Name */}
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              First Name*
            </label>
            <input
              type="text"
              name="firstName"
              placeholder="First Name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.firstName}
              className="mt-1 w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
            />
            {formik.touched.firstName && formik.errors.firstName && (
              <div className="text-red-500 text-sm font-bold">
                {formik.errors.firstName}
              </div>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              Last Name*
            </label>
            <input
              type="text"
              name="lastName"
              placeholder="Last Name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.lastName}
              className="mt-1 w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
            />
            {formik.touched.lastName && formik.errors.lastName && (
              <div className="text-red-500 text-sm font-bold">
                {formik.errors.lastName}
              </div>
            )}
          </div>
        </div>

        {/* Contact Person Title & Email */}
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Contact Person Title*
            </label>
            <input
              type="text"
              name="contactPersonTitle"
              placeholder="Contact Person Title"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.contactPersonTitle}
              className="mt-1 w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
            />
            {formik.touched.contactPersonTitle &&
              formik.errors.contactPersonTitle && (
                <div className="text-red-500 text-sm font-bold">
                  {formik.errors.contactPersonTitle}
                </div>
              )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              Contact Person Email*
            </label>
            <input
              type="email"
              name="email"
              placeholder="Contact Person Email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              className="mt-1 w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
            />
            {formik.touched.email && formik.errors.email && (
              <div className="text-red-500 text-sm font-bold">
                {formik.errors.email}
              </div>
            )}
          </div>
        </div>

        {/* Mobile Number */}
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Mobile Number*
          </label>
          <input
            type="text"
            name="phone"
            placeholder="Mobile Number"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.phone}
            className="mt-1 w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
          />
          {formik.touched.phone && formik.errors.phone && (
            <div className="text-red-500 text-sm font-bold">
              {formik.errors.phone}
            </div>
          )}
        </div>

        {/* City, State & Zipcode */}
        <div className="grid grid-cols-3 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              City*
            </label>
            <input
              type="text"
              name="city"
              placeholder="City"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.city}
              className="mt-1 w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
            />
            {formik.touched.city && formik.errors.city && (
              <div className="text-red-500 text-sm font-bold font-bold">
                {formik.errors.city}
              </div>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              State*
            </label>
            <input
              type="text"
              name="state"
              placeholder="State"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.state}
              className="mt-1 w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
            />
            {formik.touched.state && formik.errors.state && (
              <div className="text-red-500 text-sm font-bold">
                {formik.errors.state}
              </div>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              Zipcode*
            </label>
            <input
              type="text"
              name="zipcode"
              placeholder="Zipcode"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.zipcode}
              className="mt-1 w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
            />
            {formik.touched.zipcode && formik.errors.zipcode && (
              <div className="text-red-500 text-sm font-bold">
                {formik.errors.zipcode}
              </div>
            )}
          </div>
        </div>

        <div className="grid grid-cols-2 gap-4">
          {/* Business Name */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Business Name*
            </label>
            <input
              type="text"
              name="businessName"
              placeholder="Business Name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.businessName}
              className="mt-1 w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
            />
            {formik.touched.businessName && formik.errors.businessName && (
              <div className="text-red-500 text-sm font-bold">
                {formik.errors.businessName}
              </div>
            )}
          </div>

          {/* Number of Locations */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Number of Locations*
            </label>
            <input
              type="text"
              name="numberOfLocations"
              placeholder="Number of Locations"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.numberOfLocations}
              className="mt-1 w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
            />
            {formik.touched.numberOfLocations &&
              formik.errors.numberOfLocations && (
                <div className="text-red-500 text-sm font-bold">
                  {formik.errors.numberOfLocations}
                </div>
              )}
          </div>
        </div>

        <div className="grid grid-cols-2 gap-4">
          {/* Primary Business Focus */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Primary Business Focus*
            </label>
            <input
              type="text"
              name="primaryBusinessFocus"
              placeholder="Primary Business Focus"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.primaryBusinessFocus}
              className="mt-1 w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
            />
            {formik.touched.primaryBusinessFocus &&
              formik.errors.primaryBusinessFocus && (
                <div className="text-red-500 text-sm font-bold">
                  {formik.errors.primaryBusinessFocus}
                </div>
              )}
          </div>

          {/* Business Address */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Business Address*
            </label>
            <input
              type="text"
              name="businessAddress"
              placeholder="Business Address"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.businessAddress}
              className="mt-1 w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
            />
            {formik.touched.businessAddress &&
              formik.errors.businessAddress && (
                <div className="text-red-500 text-sm font-bold">
                  {formik.errors.businessAddress}
                </div>
              )}
          </div>
        </div>

        <div className="grid grid-cols-2 gap-4">
          {/* Business Website Address */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Business Website Address
            </label>
            <input
              type="url"
              name="businessWebsiteAddress"
              placeholder="https://businesswebsite.com"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.businessWebsiteAddress}
              className="mt-1 w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
            />
            {formik.touched.businessWebsiteAddress &&
              formik.errors.businessWebsiteAddress && (
                <div className="text-red-500 text-sm font-bold">
                  {formik.errors.businessWebsiteAddress}
                </div>
              )}
          </div>

          {/* Address 2 */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Address 2
            </label>
            <input
              type="text"
              name="address2"
              placeholder="Suite, Floor, etc."
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.address2}
              className="mt-1 w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
            />
            {formik.touched.address2 && formik.errors.address2 && (
              <div className="text-red-500 text-sm font-bold">
                {formik.errors.address2}
              </div>
            )}
          </div>
        </div>

        <div className="grid grid-cols-2 gap-4">
          {/* Business Description */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Business Description*
            </label>
            <input
              type="text"
              name="businessDescription"
              placeholder="Brief description of the business"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.businessDescription}
              className="mt-1 w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
            />
            {formik.touched.businessDescription &&
              formik.errors.businessDescription && (
                <div className="text-red-500 text-sm font-bold">
                  {formik.errors.businessDescription}
                </div>
              )}
          </div>

          {/* Federal Business ID */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Federal Business ID*
            </label>
            <input
              type="text"
              name="federalBusinessId"
              placeholder="Federal Business ID"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.federalBusinessId}
              className="mt-1 w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
            />
            {formik.touched.federalBusinessId &&
              formik.errors.federalBusinessId && (
                <div className="text-red-500 text-sm font-bold">
                  {formik.errors.federalBusinessId}
                </div>
              )}
          </div>
        </div>

        <div className="grid grid-cols-2 gap-4">
          {/* Tax ID */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Tax ID*
            </label>
            <input
              type="text"
              name="taxId"
              placeholder="Tax ID"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.taxId}
              className="mt-1 w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
            />
            {formik.touched.taxId && formik.errors.taxId && (
              <div className="text-red-500 text-sm font-bold">
                {formik.errors.taxId}
              </div>
            )}
          </div>

          {/* Headquarters Address */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Headquarters Address
            </label>
            <input
              type="text"
              name="headquartersAddress"
              placeholder="Headquarters Address"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.headquartersAddress}
              className="mt-1 w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
            />
            {formik.touched.headquartersAddress &&
              formik.errors.headquartersAddress && (
                <div className="text-red-500 text-sm font-bold">
                  {formik.errors.headquartersAddress}
                </div>
              )}
          </div>
        </div>

        <div className="grid grid-cols-2 gap-4">
          {/* Business Verification Document */}
          <div>
            <label htmlFor="businessVerificationDocument">
            Please upload one of the documents in JPG or PNG format.            </label>
            <p className="text-sm text-gray-600 mb-2">
  
    - Business License <br />
    - Reseller Tax ID <br />
    - Federal Tax ID/EIN <br />
    - OTP (Other Tobacco Products) Tax Permit
  </p>
            <input
              id="businessVerificationDocument"
              name="businessVerificationDocument"
              type="file"
              onChange={handleFileChange}
            />
            {formik.touched.businessVerificationDocument &&
            formik.errors.businessVerificationDocument ? (
              <div className="text-red-500 text-sm font-bold">
                {formik.errors.businessVerificationDocument}
              </div>
            ) : null}
          </div>

          {/* Heard About Us */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              How did you hear about us?
            </label>
            <select
              name="heardAboutUs"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.heardAboutUs}
              className="mt-1 w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
            >
              <option value="Google search">Google search</option>
              <option value="Referral">Referral</option>
              <option value="Social Media">Social Media</option>
              <option value="Other">Other</option>
            </select>
            {formik.touched.heardAboutUs && formik.errors.heardAboutUs && (
              <div className="text-red-500 text-sm font-bold">
                {formik.errors.heardAboutUs}
              </div>
            )}
          </div>
        </div>
        <div className="mx-auto flex justify-center">
          <button
            type="submit"
            className="w-64 bg-blue-500 text-white py-2 px-4 rounded-lg"
          >
            {formik.isSubmitting ? "Submitting..." : "Submit"}
          </button>
        </div>
      </form>
      
    </div>
    
  );
};

export default VendorRegister;

