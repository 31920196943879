import React from "react";

const ShopByBrands = () => {
  return (
    <div className="bg-gray-50 py-12">
      <div className="container mx-auto px-6 text-center">
        {/* Section Title */}
        <h2 className="text-4xl font-extrabold text-gray-900 mb-8">Shop by Brands</h2>
        <p className="text-gray-600 mb-12 text-lg">
          Explore your favorite brands and shop their collections with ease.
        </p>
        {/* Brand Logos */}
        <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-8">
          {/* Brand Card */}
          {["Brand 1", "Brand 2", "Brand 3", "Brand 4", "Brand 5", "Brand 6"].map(
            (brand, index) => (
              <div
                key={index}
                className="p-6 bg-white rounded-lg shadow-md hover:shadow-xl transition-transform transform hover:scale-105"
              >
                <img
                  src="https://buffalodistro.com/wp-content/uploads/2022/12/My-project-1-2022-12-22T104135.475.png"
                  alt={brand}
                  className="w-full h-24 object-contain mx-auto"
                />
                <p className="mt-4 text-gray-700 font-medium">{brand}</p>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default ShopByBrands;
