import React, { useState, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";

const AgeVerificationPopup = () => {
  const navigate = useNavigate();
    const [isAgeVerified, setIsAgeVerified] = useState(false);
  const [showForbidden, setShowForbidden] = useState(false);

  // Check if the age verification status is already stored in localStorage
  useEffect(() => {
    const ageVerified = localStorage.getItem("ageVerified");
    if (ageVerified === "true") {
      setIsAgeVerified(true); // Age is verified, no popup needed
    }
  }, []);

  // Handle when the user clicks on "I am 21 or Older"
  const handleAgeConfirmation = () => {
    localStorage.setItem("ageVerified", "true");
    setIsAgeVerified(true);
    navigate("/")
  };

  // Handle when the user clicks on "I am Under 21"
  const handleAgeDenial = () => {
    setShowForbidden(true);
  };

  // Handle closing the "forbidden" message
  const handleCloseForbidden = () => {
    setShowForbidden(false);
  };

  // Don't show anything if age is verified
  if (isAgeVerified) {
    return null; // Return nothing if age is verified
  }

  return (
    <>
      {/* Age Verification Popup */}
      <div
        className={`fixed inset-0 bg-black bg-opacity-70 flex justify-center items-center z-50 ${showForbidden ? "hidden" : ""}`}
      >
        <div className="bg-white p-8 rounded-lg shadow-lg text-center w-80">
          <h2 className="text-xl font-semibold mb-4">Are you over 21?</h2>
          <p className="mb-6">You must be 21 years of age or older to view this page. Please verify your age to enter.</p>
          <div className="flex justify-center space-x-4">
            <button
              onClick={handleAgeConfirmation}
              className="px-6 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600"
            >
              I am 21 or Older
            </button>
            <button
              onClick={handleAgeDenial}
              className="px-6 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600"
            >
              I am Under 21
            </button>
          </div>
        </div>
      </div>

      {/* Forbidden Message */}
      <div
        className={`fixed inset-0 bg-black bg-opacity-70 flex justify-center items-center z-50 ${!showForbidden ? "hidden" : ""}`}
      >
        <div className="bg-white p-8 rounded-lg shadow-lg text-center w-80">
          <h2 className="text-xl font-semibold mb-4">Access Forbidden</h2>
          <p className="mb-6">Your access is restricted because of your age.</p>
          <button
            onClick={handleCloseForbidden}
            className="px-6 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600"
          >
            Close
          </button>
        </div>
      </div>
    </>
  );
};

export default AgeVerificationPopup;
