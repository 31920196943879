import { createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";

const initialState = {
  cartItems: localStorage.getItem("cartItems")
    ? JSON.parse(localStorage.getItem("cartItems"))
    : [],
  cartTotalQuantity: 0,
  cartTotalAmount: 0,
};

export const CartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart(state, action) {
      const product = state.cartItems.findIndex(
        (item) => item._id === action.payload._id
      );
      if (product >= 0) {
        state.cartItems[product].cartQuantity += 1;
      // } else {
        // const author = state.cartItems[0]?.author;
        // const productAuthor = action.payload?.author;
        // if(author){
        //   if(author?._id === productAuthor?._id){
            const tempProduct = { ...action.payload, cartQuantity: 1 };
            state.cartItems.push(tempProduct);
          // }else{
          //   toast.error(`You can add only ${author?.name} products`);
          // }
        }else{
          const tempProduct = { ...action.payload, cartQuantity: 1 };
          state.cartItems.push(tempProduct);
        }
      
      toast.success('Item added to cart');
      localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
    },

    removeFromCart(state, action) {
      state.cartItems.map((cartItem) => {
        if (cartItem._id === action.payload._id) {
          const nextCartItems = state.cartItems.filter(
            (item) => item._id !== cartItem._id
          );

          state.cartItems = nextCartItems;
        }
        toast.success('Item removed from cart');
        localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
        return state;
      });
    },
    decreaseCart(state, action) {
      const itemIndex = state.cartItems.findIndex(
        (item) => item._id === action.payload._id
      );


      if (state.cartItems[itemIndex].cartQuantity > 1) {
        state.cartItems[itemIndex].cartQuantity -= 1;
      }else{
        const nextCartItems = state.cartItems.filter(
          (item) => item._id !== state.cartItems[itemIndex]._id
        );
        toast.success('Item removed from cart');
        state.cartItems = nextCartItems;
      }
      toast.success('Item removed from cart');
      localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
    },
    incrementCart(state, action) {
      const product = state.cartItems.findIndex(
        (item) => item._id === action.payload._id
      );
      if (product >= 0 ) {
        if(state.cartItems[product].quantity > state.cartItems[product].cartQuantity){
          state.cartItems[product].cartQuantity += 1;
        }else{
          toast.error('No more stock available');
        }
      } else {
        const tempProduct = { ...action.payload, cartQuantity: 1 };
        state.cartItems.push(tempProduct);
      }
     toast.success("Item added to cart")
      localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
    },
    addByIncrement(state, action) {
      const product = state.cartItems.findIndex(
        (item) => item._id === action.payload.product._id
      );
      if (product >= 0) {
        state.cartItems[product].cartQuantity +=action.payload.cartQuantity;
      } else {
        const cartQuantity = action.payload.cartQuantity===1?1:action.payload.cartQuantity 
        const tempProduct = { ...action.payload.product,cartQuantity};
        state.cartItems.push(tempProduct);
      }
     toast.success("Item added to cart") 
      localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
    },
    getTotals(state, action) {
      let { total, quantity } = state.cartItems.reduce(
        (cartTotal, cartItem) => {
          const { price, cartQuantity } = cartItem;
          const itemTotal = price * cartQuantity;

          cartTotal.total += itemTotal;
          cartTotal.quantity += cartQuantity;

          return cartTotal;
        },
        {
          total: 0,
          quantity: 0,
        }
      );
      total = parseFloat(total.toFixed(2));
      state.cartTotalQuantity = quantity;
      state.cartTotalAmount = total;
    },
    emptyCart(state){
      state.cartItems=[];
      state.cartTotalQuantity=0;
      state.cartTotalAmount=0;
      toast.success("Items removed from the cart")
      localStorage.removeItem('cartItems');
    }
  },
});

export const { addToCart, removeFromCart,decreaseCart,incrementCart, getTotals,addByIncrement, emptyCart} = CartSlice.actions;

export const cartReducer = CartSlice.reducer;
